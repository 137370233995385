import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import { Golfbox, Layout, Metadata, Image, BlogContent, PreviewContent } from '../components';
import { theme, media, color } from '../theme';
import { vr } from '../utils';
import FacebookIcon from '../img/facebook.inline.svg';

export const NewsPostTemplate = ({ content, title, description, image, isPreviewMode, date }) => {

  const PageContent = isPreviewMode ? PreviewContent : BlogContent;

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <section>

      <div className="full-width-image fixed-background slim" id="hero-image">
        <Image src={image} />
        <div className="container">
          <h1>{title}</h1>
        </div>
      </div>

      <section>
        <div className="container">
          <div>
            <PageContent description={description}>{content}</PageContent>
            { !isPreviewMode && (
              <SocialLink href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}>
                <FacebookIcon />
                Del på Facebook
              </SocialLink>
            )}
          </div>
        </div>
      </section>

    </section>
  );
};

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]).isRequired,
  date: PropTypes.string.isRequired,
};

NewsPostTemplate.defaultProps = {

};

const Jumbotron = styled.div`
  max-height: calc(100vh - 200px);
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const NewsPost = ({ data }) => {
  const { mdx: post } = data;

  const { metadata } = post.frontmatter;
  const title = metadata?.title.length > 1 ? metadata.title : post.frontmatter.title;
  const description = metadata?.description.length > 1 ? metadata.description : '';

  return (
    <Layout>
      <Metadata title={title} description={description} image={post.frontmatter.image} />
      <NewsPostTemplate
        title={post.frontmatter.title}
        content={post.body}
        image={post.frontmatter.image}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
      />
    </Layout>
  );
};

const SocialLink = styled.a`
  line-height: 32px;
  display: inline-block;
  margin-top: ${vr(1)};

  svg, img {
    height: 32px;
    width: 32px;
    margin: 0 10px 0 0;
    float: left;
  }
`;

NewsPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }).isRequired,
};

export default NewsPost;

export const newsQuery = graphql`
  query NewsPostByID($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date(formatString: "D. MMMM YYYY", locale: "DA")
        title
        description
        metadata {
          title
          description
        }
        image {
          childImageSharp {
            fluid(
              maxWidth: 2048,
              quality: 100,
              duotone: {
                highlight: "#cc3232",
                shadow: "#51133d"
              }
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
